<template>
  <div>
    <div class="card card-custom gutter-b" id="locationSectionsB">
      <div class="card-header d-flex align-items-center">
        <h3 class="card-title font-weight-bolder text-dark">
          {{ $t('COMPARATIVE_ANALYSIS.DEVICE_B_REPORT') }}:
          <span class="ml-5"
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.start | date('dd.MM.yyyy') }}</span
            ></span
          >
          <span
            ><span class="flex-shrink-0 my-2 mx-3"
              ><i class="la la-arrow-right"></i></span
          ></span>
          <span
            ><span
              class="label label-lg label-inline label-light-primary font-weight-bold"
              >{{ range.end | date('dd.MM.yyyy') }}</span
            ></span
          >
        </h3>
        <div class="card-toolbar">
          <button
            @click.prevent="exportAsExcel"
            class="btn btn-light-primary font-weight-bold px-10"
          >
            {{ $t('GENERAL.EXPORT') }} .xlsx
          </button>
        </div>
      </div>
      <div class="card-body">
        <DeviceReportTable v-if="isGenerated" :traffic="traffic">
        </DeviceReportTable>
      </div>
    </div>
  </div>
</template>

<script>
import format from 'date-fns/format'
import { mapGetters } from 'vuex'
import DeviceReportTable from '@/view/content/components/device-report-table/DeviceReportTable'

export default {
  name: 'DevicesBTraffic',
  props: {
    traffic: {
      type: Array,
    },
    selectedB: {
      type: Array,
    },
    range: {
      type: Object,
    },
  },
  components: {
    DeviceReportTable,
  },
  data() {
    return {
      isGenerated: false,
    }
  },
  computed: {
    ...mapGetters(['currentUserCompanyOib']),
  },
  mounted() {
    setTimeout(() => {
      this.isGenerated = true
    }, 500)
  },
  methods: {
    exportAsExcel() {
      const from = format(this.range.start, "yyyy-MM-dd'T'HH:mm:ss")
      const end = format(this.range.end, "yyyy-MM-dd'T'HH:mm:ss")

      let temLocationsIds = ''
      this.selectedB.forEach((location) => {
        let l = location.replace('/api/v1/devices/', '')
        temLocationsIds += l + ','
      })

      window
        .open(
          `${process.env.VUE_APP_ROUTE_API_URL}/api/v1/exports/xlsx/devices-revenue/${this.currentUserCompanyOib}/${temLocationsIds}/${from}/${end}`,
          '_blank'
        )
        .focus()
    },
  },
}
</script>
